define("tracked-toolbox/index", ["exports", "@ember/debug", "@ember/object", "@glimmer/tracking", "@glimmer/tracking/primitives/cache"], function (_exports, _debug, _object, _tracking, _cache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cached = cached;
  _exports.dedupeTracked = dedupeTracked;
  _exports.localCopy = localCopy;
  _exports.trackedReset = trackedReset;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Meta = (_class = class Meta {
    constructor() {
      _defineProperty(this, "prevRemote", void 0);
      _defineProperty(this, "peek", void 0);
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  function getOrCreateMeta(instance, metas, initializer) {
    let meta = metas.get(instance);
    if (meta === undefined) {
      meta = new Meta();
      metas.set(instance, meta);
      meta.value = meta.peek = typeof initializer === 'function' ? initializer.call(instance) : initializer;
    }
    return meta;
  }
  function localCopy(memo, initializer) {
    (false && !(typeof memo === 'string' || typeof memo === 'function') && (0, _debug.assert)(`@localCopy() must be given a memo path or memo function as its first argument, received \`${String(memo)}\``, typeof memo === 'string' || typeof memo === 'function'));
    (false && !(typeof memo !== 'function') && (0, _debug.deprecate)('Using a memoization function with @localCopy has been deprecated. Consider using @trackedReset instead.', typeof memo !== 'function', {
      id: 'local-copy-memo-fn',
      for: 'tracked-toolbox',
      since: '1.2.3',
      until: '2.0.0'
    }));
    let metas = new WeakMap();
    return (_prototype, key) => {
      let memoFn = typeof memo === 'function' ? (obj, last) => memo.call(obj, obj, key, last) : obj => (0, _object.get)(obj, memo);
      return {
        get() {
          let meta = getOrCreateMeta(this, metas, initializer);
          let {
            prevRemote
          } = meta;
          let incomingValue = memoFn(this, prevRemote);
          if (prevRemote !== incomingValue) {
            // If the incoming value is not the same as the previous incoming value,
            // update the local value to match the new incoming value, and update
            // the previous incoming value.
            meta.value = meta.prevRemote = incomingValue;
          }
          return meta.value;
        },
        set(value) {
          if (!metas.has(this)) {
            let meta = getOrCreateMeta(this, metas, initializer);
            meta.prevRemote = memoFn(this);
            meta.value = value;
            return;
          }
          getOrCreateMeta(this, metas, initializer).value = value;
        }
      };
    };
  }
  function trackedReset(memoOrConfig) {
    (false && !(typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || typeof memoOrConfig === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined) && (0, _debug.assert)(`@trackedReset() must be given a memo path, a memo function, or config object with a memo path or function as its first argument, received \`${String(memoOrConfig)}\``, typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || typeof memoOrConfig === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined));
    let metas = new WeakMap();
    return (_prototype, key, desc) => {
      let memo, update;
      let initializer = desc.initializer ?? (() => undefined);
      if (typeof memoOrConfig === 'object') {
        memo = memoOrConfig.memo;
        update = memoOrConfig.update ?? initializer;
      } else {
        memo = memoOrConfig;
        update = initializer;
      }
      let memoFn = typeof memo === 'function' ? (obj, last) => memo.call(obj, obj, key, last) : obj => (0, _object.get)(obj, memo);
      return {
        get() {
          let meta = getOrCreateMeta(this, metas, initializer);
          let {
            prevRemote
          } = meta;
          let incomingValue = memoFn(this, prevRemote);
          if (incomingValue !== prevRemote) {
            meta.prevRemote = incomingValue;
            meta.value = meta.peek = update.call(this, this, key, meta.peek);
          }
          return meta.value;
        },
        set(value) {
          getOrCreateMeta(this, metas, initializer).value = value;
        }
      };
    };
  }
  function cached(target, key, value) {
    (false && !(value && value.get) && (0, _debug.assert)('@cached can only be used on getters', value && value.get));
    let {
      get,
      set
    } = value;
    let caches = new WeakMap();
    return {
      get() {
        let cache = caches.get(this);
        if (cache === undefined) {
          cache = (0, _cache.createCache)(get.bind(this));
          caches.set(this, cache);
        }
        return (0, _cache.getValue)(cache);
      },
      set
    };
  }
  function dedupeTracked() {
    let comparator;
    const descriptor = function (target, key, desc) {
      let {
        initializer
      } = desc;
      let {
        get,
        set
      } = (0, _tracking.tracked)(target, key, desc);
      let values = new WeakMap();
      return {
        get() {
          if (!values.has(this)) {
            let value = initializer?.call(this);
            values.set(this, value);
            set.call(this, value);
          }
          return get.call(this);
        },
        set(value) {
          if (!values.has(this) || !comparator(value, values.get(this))) {
            values.set(this, value);
            set.call(this, value);
          }
        }
      };
    };
    if (arguments.length === 3) {
      comparator = (a, b) => a === b;
      return descriptor(...arguments);
    }
    if (arguments.length === 1 && typeof arguments[0] === 'function') {
      comparator = arguments[0];
      return descriptor;
    }
    (false && !(false) && (0, _debug.assert)(`@dedupeTracked() can either be invoked without arguments or with one comparator function, received \`${String(arguments)}\``, false));
  }
});