define("ember-svg-jar/inlined/dashboard-high-performing-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" fill=\"#fff\"><path d=\"M0 0h30v30H0z\" fill-rule=\"evenodd\"/></mask><g fill=\"#29c3d8\" fill-rule=\"evenodd\"><path d=\"M18.566 11.89c0-2.13-1.696-3.856-3.788-3.856S10.99 9.76 10.99 11.89s1.696 3.856 3.788 3.856 3.788-1.727 3.788-3.856\"/><path d=\"M21.82 23.728l-2.115-.081-1.456 1.537a.643.643 0 01-1.062-.2l-2.09-5.124c.435.242.919.385 1.418.385a2.91 2.91 0 002.073-.859c.349-.348.163-.249 1.01-.476a2.896 2.896 0 001.02-.534l1.822 4.467a.643.643 0 01-.62.885zm-7.896-5.017a1.597 1.597 0 01-1.998-.233c-.591-.603-.658-.564-1.557-.81a1.632 1.632 0 01-1.143-1.163c-.3-1.14-.223-1.003-1.042-1.837a1.668 1.668 0 01-.419-1.59c.3-1.141.3-.983 0-2.121-.15-.568.01-1.174.419-1.59.82-.834.742-.697 1.042-1.837a1.63 1.63 0 011.143-1.164c1.12-.306.985-.226 1.805-1.06a1.596 1.596 0 011.562-.427c1.12.306.965.305 2.084 0a1.598 1.598 0 011.562.426c.82.835.685.755 1.805 1.06.558.153.993.597 1.143 1.165.3 1.14.223 1.003 1.042 1.837.409.416.568 1.022.419 1.59-.3 1.14-.3.981 0 2.121.15.568-.01 1.174-.419 1.59-.82.834-.742.696-1.042 1.837a1.633 1.633 0 01-1.143 1.164c-.874.238-.945.184-1.558.809a1.596 1.596 0 01-1.997.233 1.595 1.595 0 00-1.708 0zm-1.556 6.274a.642.642 0 01-1.061.2L9.85 23.646l-2.116.08a.643.643 0 01-.62-.884l1.823-4.467c.303.235.64.432 1.02.534.842.226.66.127 1.01.476a2.91 2.91 0 002.072.859c.5 0 .983-.142 1.418-.385l-2.09 5.125zM15 0C6.716 0 0 6.716 0 15s6.716 15 15 15 15-6.716 15-15S23.284 0 15 0z\" mask=\"url(#a)\"/></g>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});