define("ember-svg-jar/inlined/dashboard-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M34.61 43.364h-9.335c-8.827 0-15.982-7.155-15.982-15.982V1.922c0-1.061.86-1.921 1.922-1.921h37.454c1.062 0 1.923.86 1.923 1.921v25.46c0 8.827-7.156 15.982-15.983 15.982\" fill=\"#edde64\"/><path d=\"M34.61 40.267h-9.335c-7.225 0-12.885-5.442-12.885-12.39a1.033 1.033 0 012.065 0c0 5.79 4.753 10.325 10.82 10.325h9.334c6.067 0 10.82-4.536 10.82-10.325a1.033 1.033 0 012.065 0c0 6.948-5.66 12.39-12.885 12.39\" fill=\"#ecba16\"/><path d=\"M38.202 53.689h-16.52v-2.136c0-1.102.892-1.994 1.994-1.994h12.532c1.102 0 1.994.892 1.994 1.994z\" fill=\"#edde64\"/><path d=\"M47.494 59.884H12.39a6.208 6.208 0 016.208-6.209h22.688a6.208 6.208 0 016.208 6.209m7.38-51.624h-4.282v4.13h4.282c.48 0 .872.528.872 1.009v9.365a7.245 7.245 0 01-3.5 6.18l-1.9 1.14a15.877 15.877 0 01-2.554 6.326l.757-.431 5.824-3.495c3.394-2.036 5.511-5.761 5.511-9.72V13.4c0-2.759-2.253-5.139-5.01-5.139M34.609 43.364h-9.334c-.168 0-.33-.02-.495-.025v6.22h10.325v-6.22c-.167.005-.329.025-.496.025M48.67 0H11.216c-1.062 0-1.923.86-1.923 1.922v4.273h41.299V1.922A1.923 1.923 0 0048.67 0M29.942 11.358l2.714 5.162h5.546l-4.13 5.162 1.032 6.195-5.162-3.014-5.162 3.014 1.032-6.195-4.13-5.162h5.546zM5.01 8.26h4.282v4.13H5.011c-.482 0-.873.528-.873 1.009v9.365a7.245 7.245 0 003.5 6.18l1.9 1.14a15.877 15.877 0 002.554 6.326l-.757-.431-5.824-3.495C2.117 30.448 0 26.723 0 22.764V13.4c0-2.76 2.253-5.14 5.01-5.14\" fill=\"#ecba16\"/></g>",
    "attrs": {
      "viewBox": "0 0 60 60",
      "height": "60",
      "width": "60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});