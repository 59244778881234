define("ember-svg-jar/inlined/breadcrumb-angle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.7 61.6H0l23.5-27.2c1.6-1.9 1.6-5 0-6.9L0 0h.7l23.2 27.1c1.8 2.1 1.8 5.4 0 7.6L.7 61.6z\" fill=\"#dce1ea\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25.2 61.6"
    }
  };
});