define("ember-svg-jar/inlined/dashboard-field-survey-review-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st2{fill:#718a9a}</style><path id=\"Path_11\" d=\"M31.4 50H2c-1.1 0-2-.9-2-2V6.4c0-1.1.9-2 2-2h29.4c1.1 0 2 .9 2 2V48c0 1.1-.9 2-2 2z\" fill=\"#646d82\"/><path id=\"Rectangle_3\" fill=\"#e4eff6\" d=\"M2.4 6.9H31v40.7H2.4z\"/><path id=\"Path_12\" class=\"st2\" d=\"M23.8 6.9l-1.3-3.3c-.2-.5-.6-.8-1.1-.8h-1.8c0-1.6-1.3-2.9-2.8-2.9-1.6 0-2.9 1.3-2.9 2.8H12c-.5 0-.9.3-1.1.8L9.6 6.9c-.2.6.1 1.3.7 1.6.1.1.3.1.4.1h11.9c.7 0 1.2-.5 1.2-1.2.1-.2.1-.4 0-.5zm-7.1-2.8c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.6 1.5-1.5 1.5z\"/><path id=\"Path_13\" class=\"st2\" d=\"M26.9 14c-.2-.3-.6-.3-.9-.1l-4 3.4-1.3-1.3c-.2-.2-.6-.3-.9 0-.2.2-.3.6 0 .9l1.6 1.6c.2.2.6.2.8 0l4.5-3.7c.3-.2.4-.6.2-.8z\"/><path id=\"Path_14\" class=\"st2\" d=\"M26.9 21.9c-.2-.3-.6-.3-.9-.1l-4 3.4-1.3-1.2c-.2-.2-.6-.3-.9 0-.2.2-.3.6 0 .9l1.6 1.6c.2.2.6.2.8 0l4.5-3.7c.3-.3.4-.7.2-.9z\"/><path id=\"Path_15\" class=\"st2\" d=\"M15.5 17.7H7c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.5c.3 0 .6.2.6.6.1.3-.2.6-.6.6.1 0 .1 0 0 0z\"/><path id=\"Path_16\" class=\"st2\" d=\"M15.5 24.7H7c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.5c.3 0 .6.2.6.6.1.3-.2.6-.6.6.1 0 .1 0 0 0z\"/><path id=\"Path_17\" class=\"st2\" d=\"M26.9 29.8c-.2-.3-.6-.3-.9-.1l-4 3.4-1.2-1.2c-.2-.2-.6-.3-.9 0-.2.2-.3.6 0 .9l1.6 1.6c.2.2.6.2.8 0l4.5-3.7c.2-.3.3-.7.1-.9z\"/><path id=\"Path_18\" class=\"st2\" d=\"M26.9 37.7c-.2-.3-.6-.3-.9-.1L22 41l-1.2-1.2c-.2-.2-.6-.3-.9 0-.2.2-.3.6 0 .9l1.6 1.6c.2.2.6.2.8 0l4.5-3.7c.2-.3.3-.7.1-.9z\"/><g id=\"Group_15\" transform=\"translate(6.43 31.433)\"><path id=\"Path_19\" class=\"st2\" d=\"M9.1 1.2H.6C.3 1.2 0 1 0 .6 0 .3.2 0 .6 0h8.5c.3 0 .6.2.6.6.1.3-.2.6-.6.6z\"/><path id=\"Path_20\" class=\"st2\" d=\"M9.1 9.1H.6c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.5c.3 0 .6.2.6.6.1.3-.2.6-.6.6z\"/></g>",
    "attrs": {
      "width": "34.4",
      "height": "40",
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 33.4 50",
      "xml:space": "preserve"
    }
  };
});