define("ember-svg-jar/inlined/dashboard-indoor-water-good", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M57.03 20.37l34.48 25.86v5.75c4.02 0 7.87.57 11.49 1.67V40.49L57.03 6 11.05 40.48v68.96h44.14c-1.72-3.56-2.87-7.41-3.45-11.49H22.55V46.23l34.48-25.86z\" fill=\"#6b9e08\" stroke-width=\"0\"/><path d=\"M91.51 63.47c-15.86 0-28.73 12.87-28.73 28.73s12.87 28.73 28.73 28.73 28.73-12.87 28.73-28.73-12.87-28.73-28.73-28.73zm-3.59 43.17L74.64 93.36l5.03-5.1v.04l8.26 8.29 15.44-15.44 5.03 5.1-20.46 20.39z\" stroke-width=\"0\" fill=\"#78b911\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 130 130"
    }
  };
});