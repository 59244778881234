define("ember-svg-jar/inlined/admin-lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#646d82\"><path d=\"M8 4.4c-.7 0-1.3.6-1.3 1.2v1.2h2.6V5.6c0-.7-.6-1.2-1.3-1.2z\"/><path d=\"M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 11.1c0 .5-.4.8-.9.8H4.9c-.5 0-.9-.4-.9-.8V7.7c0-.5.4-.8.9-.8h.4V5.6C5.3 4.2 6.5 3 8 3s2.7 1.2 2.7 2.6v1.2h.4c.5 0 .9.4.9.8z\"/></g>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});