define("ember-svg-jar/inlined/dashboard-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 3.097a2.839 2.839 0 110 5.677 2.839 2.839 0 010-5.677zm0 11.097a6.182 6.182 0 01-4.726-2.2c.607-1.142 1.794-1.93 3.178-1.93a.79.79 0 01.229.036c.419.135.858.223 1.319.223s.903-.088 1.32-.223a.79.79 0 01.228-.035c1.384 0 2.571.787 3.178 1.929A6.182 6.182 0 018 14.194z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});