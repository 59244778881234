define("ember-svg-jar/inlined/dashboard-indoor-water-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M57.35 20.37l34.48 25.86v5.75c4.02 0 7.87.57 11.49 1.67V40.49L57.35 6 11.37 40.48v68.96h44.14c-1.72-3.56-2.87-7.41-3.45-11.49H22.87V46.23l34.48-25.86z\" stroke-width=\"0\" fill=\"#bf4036\"/><path d=\"M91.83 63.47c-15.86 0-28.73 12.87-28.73 28.73s12.87 28.73 28.73 28.73 28.73-12.87 28.73-28.73-12.87-28.73-28.73-28.73zm3.74 45.57h-7.48v-7.48h7.48v7.48zm0-14.97h-7.48V75.36h7.48v18.71z\" fill=\"#d8594a\" stroke-width=\"0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 130 130"
    }
  };
});