define("ember-svg-jar/inlined/dashboard-tune-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M10 10h6c-.333-1.75-4.067-7-6-7z\" fill=\"#a3aaaf\"/><path d=\"M30.515 38.705l-11.347-18.17c-1.902-3.112-2.96-6.788-2.96-10.465H4.229V3C2.185 3.99 0 6.252 0 9.363c0 3.04 1.198 4.667 3.171 5.798 4.229 2.333 7.823 5.656 10.43 9.757L24.454 42.24c.987 1.697 3.1 2.263 4.792 1.273a3.495 3.495 0 001.269-4.808\" fill=\"#e0e0e0\"/><g fill=\"#a3aaaf\"><path d=\"M9.044 15.845h4.227v-2.798H9.044zM25.249 37.538h-3.382a.54.54 0 00-.352.14l.775 1.26h2.959c.422 0 .704-.28.704-.7 0-.42-.282-.7-.704-.7m-2.114-2.8h-3.03a.54.54 0 00-.352.14l.846 1.26h2.536c.423 0 .704-.28.704-.7 0-.42-.281-.7-.704-.7\"/></g><path d=\"M40 16.75C40 12.21 32 0 32 0s-8 12.211-8 16.75c0 4.537 3.6 8.25 8 8.25s8-3.713 8-8.25z\" fill=\"#8ad7f8\"/><path d=\"M32.232 23C28.817 23 26 20.183 26 16.768c0-.427.341-.768.768-.768s.769.341.769.768c0 2.561 2.134 4.695 4.695 4.695.427 0 .768.342.768.769a.765.765 0 01-.768.768z\" fill=\"#fff\"/></g>",
    "attrs": {
      "height": "44",
      "viewBox": "0 0 40 44",
      "width": "40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});