define("ember-bootstrap-cp-validations/components/bs-form", ["exports", "@ember/object/proxy", "ember-bootstrap/components/bs-form"], function (_exports, _proxy, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ValidatedBsFrom extends _bsForm.default {
    constructor(...args) {
      super(...args);
      // Silence subclassing warning, as we need to extend for our current validations API. This means we need to treat
      // this addon as a "privileged" one, and guarantee that we will account for possible changes that would things break
      // due to us extending from the base class (e.g. when refactoring bs-form components to Glimmer.Component)
      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }
    get hasValidator() {
      return !!this.model?.validate;
    }
    async validate(model) {
      let m = model;
      if (model instanceof _proxy.default && model.get('content') && typeof model.get('content').validate === 'function') {
        m = model.get('content');
      }
      await m.validate();
      if (!model.validations.isTruelyValid) {
        throw new Error();
      }
    }
  }
  _exports.default = ValidatedBsFrom;
});