define("ember-svg-jar/inlined/card-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M233.856 123.904V6.756c0-3.411-3.494-6.176-7.807-6.176H8.388C4.076.58.581 3.345.581 6.756v116.782c231.136.165.056.025 233.275.366z\" fill=\"#f7faff\"/><path d=\"M8.388 282.403H226.05c4.312 0 7.807-3.788 7.807-8.462V87.958c-25.716-14.878-63.852-18.661-114.805-18.661-52.144 0-91.589 3.7-118.472 18.159v186.485c.001 4.674 3.495 8.462 7.808 8.462z\" fill=\"#fff\"/><path d=\"M226.126 283.564H8.889c-4.9 0-8.89-3.357-8.89-7.485V7.485C-.001 3.358 3.986 0 8.889 0h217.237c4.9 0 8.89 3.358 8.89 7.485v268.594c0 4.127-3.987 7.485-8.89 7.485zM8.889 1.849c-3.691 0-6.693 2.528-6.693 5.636v268.594c0 3.107 3 5.636 6.693 5.636h217.237c3.691 0 6.693-2.528 6.693-5.636V7.485c0-3.107-3-5.636-6.693-5.636z\" fill=\"#f5f7fb\"/>",
    "attrs": {
      "height": "283.564",
      "viewBox": "0 0 235.017 283.564",
      "width": "235.017",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});