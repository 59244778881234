define("google-maps-data/components/g-map-addons/featureOld", ["exports", "@ember/component", "ember-google-maps/components/g-map/map-component", "@ember/template-factory"], function (_exports, _component, _mapComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-yield-only }}
  {{yield}}
  */
  {
    "id": "txKslas7",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "google-maps-data/components/g-map-addons/featureOld.hbs",
    "isStrictMode": false
  });
  /* eslint-disable @typescript-eslint/ban-types */

  class FeatureOld extends _mapComponent.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_feature", void 0);
    }
    get name() {
      return 'featuresOld';
    }
    setup() {
      let data = this.args.data;
      let gFeature = data.add(this.args.feature);
      this._feature = gFeature;

      // what gets returned here gets set as the 'this.mapComponent'
      // if this isn't set, the teardown fn won't be called..
      // used in our test system as well to be able to ask questions of this particular data value
      return gFeature;
    }
    teardown(mapComponent) {
      let data = this.args.data;
      if (this._feature) {
        data.remove(this._feature);
      }
      super.teardown(mapComponent);
    }
  }
  _exports.default = FeatureOld;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FeatureOld);
});