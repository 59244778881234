define("ember-select-light/helpers/is-equal", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = _exports.default = void 0;
  const isEqual = ([left, right]) => left === right;
  _exports.isEqual = isEqual;
  var _default = _exports.default = (0, _helper.helper)(isEqual);
});