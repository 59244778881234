define("ember-svg-jar/inlined/clear-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 6h14V4H2zm-2 4h14V8H0zM4 0v2h14V0z\" fill=\"#646d82\"/>",
    "attrs": {
      "height": "10",
      "viewBox": "0 0 18 10",
      "width": "18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});