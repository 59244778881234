define("ember-google-maps-kml/components/g-map-addons/kml", ["exports", "@ember/component", "ember-google-maps/components/g-map/map-component", "@ember/template-factory"], function (_exports, _component, _mapComponent, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-yield-only }}
  {{yield}}
  */
  {
    "id": "CVBMv4R0",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-google-maps-kml/components/g-map-addons/kml.hbs",
    "isStrictMode": false
  });
  /* global google */

  class Kml extends _mapComponent.default {
    get name() {
      return 'kml';
    }
    setup() {
      let kmlLayer = new google.maps.KmlLayer(this._getOptions());
      kmlLayer.setMap(this.map);
      if (this.args.onPolygonClick) {
        this._listener = google.maps.event.addListener(kmlLayer, 'click', event => {
          this.args.onPolygonClick(event);
        });
      }
      return kmlLayer;
    }
    _getOptions() {
      let options = {};
      ['url', 'preserveViewport', 'suppressInfoWindows'].forEach(prop => options[prop] = this.args[prop]);
      return options;
    }
    update(mapComponent) {
      mapComponent?.setOptions?.(this._getOptions());
      return mapComponent;
    }
    teardown() {
      let kmlLayer = this.mapComponent;

      // tear down our event listener when this component goes away
      if (this._listener) {
        google.maps.event.removeListener(this._listener);
      }
      kmlLayer.setMap(null);
    }
  }
  _exports.default = Kml;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Kml);
});