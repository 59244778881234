define("ember-svg-jar/inlined/dashboard-field-survey-offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"30.5\" cy=\"38.5\" fill=\"#fff\" r=\"11.5\"/><path d=\"M30.3 26.9c.6 0 1.2.1 1.9.1V6H1v42.6h24.6c-9.7-4.2-8.1-21.7 4.7-21.7z\" fill=\"#e4eff6\"/><path d=\"M23.8 6.9l-1.3-3.3c-.2-.5-.6-.8-1.1-.8h-1.8c0-1.6-1.3-2.9-2.8-2.9-1.6 0-2.9 1.3-2.9 2.8H12c-.5 0-.9.3-1.1.8L9.6 6.9c-.2.6.1 1.3.7 1.6.1.1.3.1.4.1h11.9c.7 0 1.2-.5 1.2-1.2.1-.2 0-.4 0-.5zm-7.1-2.8c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.6 1.5-1.5 1.5z\" fill=\"#718a9a\"/><path d=\"M33.4 26.2V6.6c0-1.1-.9-2-2-2h-8.6l1 2.4H31v18.9h-.5c-6.9 0-12.5 5.6-12.5 12.5 0 3.6 1.6 7.1 4.3 9.5l-.1-.1H2.4V7h7.2l1-2.4H2c-1.1 0-2 .9-2 2v41.6c0 1.1.9 2 2 2h24.4c6.5 2.3 13.6-1.2 15.9-7.7s-1.2-13.6-7.7-15.9c-.4-.2-.8-.3-1.2-.4zm-2.9 22.6c-5.8 0-10.5-4.7-10.5-10.5s4.7-10.5 10.5-10.5S41 32.5 41 38.3s-4.7 10.5-10.5 10.5z\" fill=\"#646d82\"/><g fill=\"#718a9a\"><path d=\"M26.9 13c-.2-.3-.6-.3-.9-.1l-4 3.4-1.2-1.2c-.2-.2-.6-.3-.9 0-.2.2-.3.6 0 .9l1.6 1.6c.2.2.6.2.8 0l4.5-3.7c.2-.2.3-.6.1-.9zM26.9 20.9c-.2-.3-.6-.3-.9-.1l-4 3.4-1.2-1.2c-.2-.2-.6-.3-.9 0-.2.2-.3.6 0 .9l1.6 1.6c.2.2.6.2.8 0l4.5-3.7c.2-.2.3-.6.1-.9zM15.6 16.8H7c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.6c.3 0 .6.2.6.6s-.3.6-.6.6zM15.6 23.8H7c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.6c.3 0 .6.3.6.6s-.3.6-.6.6zM15.6 31.7H7c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.6c.3 0 .6.3.6.6s-.3.6-.6.6zM15.6 39.6H7c-.3 0-.6-.2-.6-.6 0-.3.2-.6.6-.6h8.6c.3 0 .6.2.6.6s-.3.6-.6.6z\"/></g><path d=\"M39.3 38.4c0 4.8-3.9 8.7-8.7 8.7s-8.7-3.9-8.7-8.7 3.9-8.7 8.7-8.7 8.7 3.9 8.7 8.7zm-8.4-5.8c-1.9 0-3.1.8-4.1 2.2-.1.2-.1.4.1.6l1.2.9c.2.1.4.1.6-.1.6-.8 1.1-1.3 2-1.3.7 0 1.6.5 1.6 1.2 0 .5-.4.8-1.1 1.2-.8.5-1.9 1-1.9 2.5v.2c0 .2.2.4.4.4h2c.2 0 .4-.2.4-.4 0-1 2.9-1 2.9-3.7-.1-2.2-2.2-3.7-4.1-3.7zm-.3 8.7c-.9 0-1.6.7-1.6 1.6s.7 1.6 1.6 1.6 1.6-.7 1.6-1.6-.7-1.6-1.6-1.6z\" fill=\"#90d71f\"/>",
    "attrs": {
      "height": "40",
      "viewBox": "0 0 43 50.8",
      "width": "34.4",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});